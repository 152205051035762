import api from '@/utils/api'

export default {
  resendAgreementLink: ({ state, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      const {
        currentApplication: {
          application: { id }
        }
      } = state
      api
        .post('applications/resend-contract-token', {
          application_id: id,
          ...payload
        })
        .then(({ data }) => {
          dispatch('fetchSingleApplication', id)
          resolve(data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response?.data)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },
  fetchAgreement: ({ state }) => {
    return new Promise((resolve, reject) => {
      const {
        currentApplication: {
          application: { id }
        }
      } = state
      api
        .post('applications/get-contract', { application_id: id })
        .then(({ data }) => {
          const {
            data: { application_contract }
          } = data
          resolve(application_contract)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },
  fetchIntent: (
    // eslint-disable-next-line
    {},
    { customer_stripe_id, customer_email, customer_name, type } = {}
  ) => {
    return new Promise((resolve, reject) => {
      api
        .post(`/server/payments/setup?type=${type}`, {
          customer_id: customer_stripe_id,
          customer_email,
          customer_name
        })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },
  signUserAgreement: ({ state, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      const {
        currentApplication: {
          application: { id }
        }
      } = state
      api
        .post('applications/sign-contract', { application_id: id, ...payload })
        .then(({ data }) => {
          dispatch('fetchSingleApplication', id)
          resolve(data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },
  verifyContractToken: ({ state, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      const {
        currentApplication: {
          application: { id }
        }
      } = state
      api
        .post('applications/verify-alternative-contract-token', {
          application_id: id,
          ...payload
        })
        .then(({ data }) => {
          dispatch('fetchSingleApplication', id)
          resolve(data)
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.message) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  }
}
